
<template>
  <!-- 登录后页脚 -->
  <div class="home-page-footer">
    <div class="left" @click="jumpOut"> Copyright ©2016-2023 南京百数来信息科技有限公司 苏ICP备17022236号-3 </div>
    <div class="right">
      <div class="guanwang" @click="skip('/')">官网</div>
      <div @click="skip('/resource')">文档</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
     skip(url) {
      this.$router.push({
        path: url,
      });
    },
      //跳出
    jumpOut() {
      window.open("https://beian.miit.gov.cn");
    },
  }
};
</script>
<style lang="scss" scoped>
.home-page-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 25px;
  font-size: 14px;
  color: #000000;
  .left {
    margin-left: 33px;
    word-spacing: 8px;
    cursor: pointer;
  }
  .right {
    display: flex;
    .guanwang {
      margin-right: 114px;
    }
    div{
      cursor: pointer;
    }
  }
}
</style>

