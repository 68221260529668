<template>
  <div class="basicInformation-main">
    <Breadcrumb :breadcrumbList="list"></Breadcrumb>

    <div class="form">
      <div class="form-main">
        <div class="box">
          <div class="title">
            <img src="@/assets/images/income/star.png" alt="图片" /><span
              >姓名：</span
            >
          </div>
          <el-input v-model="userInfo.name" placeholder="请输入姓名"   maxlength="1000"></el-input>
        </div>
        <div class="box">
          <div class="title">
            <img src="@/assets/images/income/star.png" alt="图片" /><span
              >邮箱：</span
            >
          </div>
          <el-input
            v-model="userInfo.email"
              maxlength="1000"
            placeholder="请输入邮箱"
          ></el-input>
        </div>
        <div class="box">
          <div class="title">
            <img src="@/assets/images/income/star.png" alt="图片" /><span
              >手机号：</span
            >
          </div>
          <el-input
            placeholder="请输入手机号"
            v-model="userInfo.phone"
              maxlength="1000"
          ></el-input>
        </div>
        <div class="button-box">
          <el-button @click="setProfile">保存</el-button>
        </div>
      </div>

      <div class="footer-fa">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Footer from "@/components/Footer/homePageFooter.vue";
import { getProfile, setProfile } from "@/api/user";

export default {
  components: { Breadcrumb, Footer },
  data() {
    return {
      list: [
        {
          name: "账号设置",
          path: null,
        },
        {
          name: "基础信息",
          path: null,
        },
      ],
      userInfo: {
        name: "",
        email: "",
        phone: "",
      },
    };
  },
  created() {
    if (this.$store.state.user.userInfo) {
      this.userInfo = JSON.parse(JSON.stringify(this.$store.state.user.userInfo));
    } else {
      this.getProfile();
    }
  },
  methods: {
    // 获取个人信息
    getProfile() {
      getProfile().then((res) => {
        if (res.status === 200) {
          this.userInfo = JSON.parse(JSON.stringify(res.data));
          this.$store.commit("user/setUserInfo", JSON.parse(JSON.stringify(res.data)));
        }
      });
    },
    //更新个人信息
    setProfile() {
      let params = {
        name: this.userInfo.name,
        email: this.userInfo.email,
        phone: this.userInfo.phone,
      };
      setProfile(params).then((res) => {
        if (res.status === 200) {
          this.getProfile();
          this.$message({
            showClose: true,
            message: "更新成功",
            type: "success",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.basicInformation-main {
  padding: 20px 0 0 20px;
  min-height: calc(100% - 20px);
  .form {
    min-height: calc(100vh - 153px);
    background-color: #fff;
    margin-top: 20px;
    position: relative;
    .form-main {
      display: flex;
      flex-direction: column;
      padding: 65px 300px 0 300px;
      align-items: center;
      .box {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 35px;
        .title {
          width: 120px;
          color: #06052b;
          margin-right: 10px;
          display: flex;
          justify-content: flex-end;
          img {
            widows: 12px;
            height: 12px;
          }
          span {
            font-size: 14px;
            color: #06052b;
          }
        }
        .el-select {
          width: calc(100% - 100px);
          /deep/ .el-input__inner {
            height: 36px;
          }
        }
        .el-input {
          width: calc(100% - 100px);
          /deep/ .el-input__inner {
            height: 36px;
          }
        }
        .el-cascader {
          width: calc(100% - 100px);
          /deep/ .el-input__inner {
            height: 36px;
          }
        }
      }
      .button-box {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 60px;
        .el-button {
          float: left;
          width: 100px;
          height: 36px;
          background: #4578ff;
          border-radius: 4px;
          color: #fff;
        }
      }
    }
    .footer-fa {
      position: absolute;
      bottom: 0;
      padding-right: 20px;
      width: calc(100% - 20px);
      background-color: #fff;
      margin-top: 150px;
      /deep/ .home-page-footer {
        margin-bottom: 0 !important;
        padding-bottom: 25px;
      }
    }
  }
}
</style>