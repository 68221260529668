<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item
      v-for="(item, index) in breadcrumbList"
      :key="index"
     
    >
      <span  @click="skip(item.path)" :class="[{ 'last-item': index === breadcrumbList.length - 1 }]">{{
        item.name
      }}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script>
export default {
  props: ["breadcrumbList"],
  data() {
    return {};
  },
  methods: {
    //跳转
    skip(path) {
      console.log(1233, path);
      if (path) {
        this.$router.push(path);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  width: calc(100% - 16px);
  height: 48px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 16px;

  /deep/ .el-breadcrumb__inner {
    color: #06052b;
    cursor: pointer;
  }

  /deep/ .el-breadcrumb__separator {
    color: #06052b;
  }

  .last-item {
    color: #4578ff;
  }
}
</style>